<template>
  <div class="menu flex_space_between">
    <div class="left flex_direction_column">
      <!-- 类型 -->
      <div class="list flex" v-if="isType">
        <div class="list_left">类型</div>
        <div class="list_right flex_wrap">
          <div
            class="item"
            :class="{ active: index == typeIndex }"
            v-for="(item, index) in menuInfo.type"
            :key="index"
            @click="itemClick(0, index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <!-- 分类 -->
      <div class="list flex">
        <div class="list_left">分类</div>
        <div class="list_right flex_wrap">
          <div
            class="item"
            :class="{ active: index == classifyIndex }"
            v-for="(item, index) in menuInfo.classify"
            :key="item.id"
            @click="itemClick(1, index)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <!-- 省 -->
      <div class="list flex">
        <div class="list_left">城市</div>
        <div class="list_right flex_wrap">
          <div
            class="item"
            :class="{ active: index == cityIndex }"
            v-for="(item, index) in menuInfo.province"
            :key="index"
            @click="itemClick(2, index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <!-- 市 -->
      <div class="list flex" v-if="cityIndex != 0">
        <div class="list_left"></div>
        <div class="list_right flex_wrap">
          <div
            class="item"
            :class="{ active: index == areaIndex }"
            v-for="(item, index) in area"
            :key="index"
            @click="itemClick(3, index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <!-- 状态 -->
      <div class="list flex">
        <div class="list_left">状态</div>
        <div class="list_right flex_wrap">
          <div
            class="item"
            :class="{ active: index == statusIndex }"
            v-for="(item, index) in menuInfo.state"
            :key="index"
            @click="itemClick(4, index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="right flex_space_between">
      <input v-model="key_words" placeholder="请输入关键词搜索" @blur="blur" />
      <img src="@/assets/image/icon/05.png" />
    </div>
  </div>
</template>

<script>
import { activeClassify, activeCity } from '@/service/api/active'
import { matchClassify, matchCity } from '@/service/api/match'
export default {
  props: {
    isType: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  data() {
    return {
      typeIndex: 0,
      cityIndex: 0,
      areaIndex: 0,
      statusIndex: 0,
      classifyIndex: 0,
      area: [],
      menuInfo: {},
      key_words: ''
    }
  },

  mounted() {
    if (this.isType) {
      // 赛事分类
      this.matchClassify()
    } else {
      // 活动分类
      this.activeClassify()
    }
  },

  methods: {
    // 赛事分类
    async matchClassify() {
      const res = await matchClassify()
      this.menuInfo = res.msg
      // 赛事城市
      this.matchCity()
    },
    // 赛事城市
    async matchCity(province) {
      const res = await matchCity({ province })
      this.area = res.msg
      this.emit()
    },
    // 活动分类
    async activeClassify() {
      const res = await activeClassify()
      this.menuInfo = res.msg
      // 活动城市
      this.activeCity()
    },
    // 活动城市
    async activeCity(province) {
      const res = await activeCity({ province })
      this.area = res.msg
      console.log('this.area', this.area)
      this.emit()
    },
    itemClick(status, index) {
      if (status == 0) {
        this.typeIndex = index
      } else if (status == 1) {
        this.classifyIndex = index
      } else if (status == 2) {
        this.cityIndex = index
        if (this.isType) {
          this.matchCity(this.menuInfo.province[index])
        } else {
          this.activeCity(this.menuInfo.province[index])
        }
      } else if (status == 3) {
        this.areaIndex = index
      } else {
        this.statusIndex = index
      }
      this.emit()
    },
    emit() {
      let params = {
        page: 1,
        type: this.typeIndex,
        classify: this.menuInfo.classify[this.classifyIndex].id,
        province: this.menuInfo.province[this.cityIndex],
        city: this.area[this.areaIndex],
        state: this.statusIndex
      }
      this.$emit('change', params)
    },
    blur() {
      this.$emit('search', this.key_words)
    }
  }
}
</script>

<style lang="less" scoped>
.active {
  color: #fff;
  background-color: @color_two;
}
.menu {
  margin-top: 20px;
  border-radius: 3px;
  background: #fff;
  align-items: flex-start;
  padding: 33px 36px 38px 35px;
  .left {
    flex: 1;
    margin-right: 20px;
    .list {
      width: 100%;
      min-height: 30px;
      line-height: 30px;
      margin-bottom: 18px;
      align-items: flex-start;
      font-size: @font_size_14;
      &:last-child {
        margin-bottom: 0;
      }
      .list_left {
        width: 70px;
        height: 30px;
        text-align: left;
        margin-right: 20px;
      }
      .list_right {
        .item {
          padding: 0 15px;
          height: 30px;
          cursor: pointer;
          margin-right: 10px;
          text-align: center;
          border-radius: 3px;
        }
      }
    }
  }
  .right {
    width: 202px;
    height: 34px;
    border-radius: 17px;
    border: 1px solid #dddddd;
    input {
      width: 140px;
      height: 20px;
      border: none;
      outline: none;
      line-height: 20px;
      margin-left: 16px;
      font-size: @font_size_14;
    }
    img {
      width: 18px;
      height: 18px;
      margin-right: 16px;
    }
  }
}
</style>
