<template>
  <div class="activity">
    <div class="list flex_direction_column">
      <div
        class="item transition flex_space_between"
        v-for="(item, index) in list"
        :key="index"
        @click="itemClick(item)"
      >
        <div class="left">
          <img :src="item.cover" />
        </div>
        <div class="center flex_direction_column">
          <span class="one_line">{{ item.name }}</span>
          <p>活动地点：{{ item.province + item.city }}</p>
          <p>报名时间：{{ item.sign_start }}</p>
          <p>活动时间：{{ item.start_time }}</p>
        </div>
        <div class="right flex_direction_column">
          <span v-if="item.price != '0.0'">¥{{ item.price }}</span>
          <span v-else>免费</span>
          <span :style="getStyle(item)">{{ getText(item) }}</span>
          <!-- <div class="box flex">
            <img src="@/assets/image/icon/03.png" />
            {{ item.club_name }}
          </div> -->
        </div>
      </div>
    </div>
    <div class="pagination" v-if="isShow">
      <el-pagination
        :page-size="15"
        @current-change="currentChange"
        background
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import statusMinxin from '@/minxin/status.js'
export default {
  mixins: [statusMinxin],
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
    isShow: {
      type: Boolean,
      default() {
        return false
      }
    },
    total: {
      type: Number,
      default() {
        return 0
      }
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {
    itemClick(item) {
      if (item.is_url != 0) return window.open(item.url)
      this.$router.push({
        path: '/activity-detail',
        query: {
          id: item.id
        }
      })
    },
    currentChange(page) {
      console.log(page)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-pager li {
  font-weight: normal;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: @color_one;
  color: #fff;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 29px;
}

.list {
  width: 100%;
  .item {
    border-radius: 3px;
    background: #fff;
    padding: 24px 20px;
    align-items: flex-start;
    cursor: pointer;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .left {
      width: 250px;
      height: 162px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
      }
    }
    .center {
      flex: 1;
      margin-left: 20px;
      span {
        height: 28px;
        // font-weight: bold;
        line-height: 28px;
        font-size: @font_size_20;
      }
      p {
        line-height: 30px;
        color: @color_seven;
        font-size: @font_size_14;
        &:nth-child(2) {
          margin-top: 10px;
        }
      }
    }
    .right {
      justify-content: flex-end;
      align-items: flex-end;
      span {
        &:nth-child(1) {
          width: 101px;
          height: 28px;
          text-align: right;
          // font-weight: bold;
          line-height: 28px;
          color: @color_one;
          font-size: @font_size_20;
        }
        &:nth-child(2) {
          width: 114px;
          height: 36px;
          color: #fff;
          margin-top: 20px;
          text-align: center;
          line-height: 36px;
          border-radius: 3px;
          background: @color_one;
        }
      }
      .box {
        img {
          width: 18px;
          height: 18px;
          margin-right: 5px;
        }
        height: 20px;
        line-height: 20px;
        margin-top: 20px;
        margin-right: 5px;
        color: @color_eight;
        font-size: @font_size_14;
      }
    }
  }
}
</style>
