<template>
  <div>
    <div class="header">
      <img :src="$store.state.config.active_banner" />
    </div>
    <div class="content">
      <Menu :isType="false" @change="menuChange" @search="search"></Menu>
      <ActivityList
        v-if="list.length != 0"
        :total="total"
        :list="list"
        :isShow="true"
        class="list"
        @page="pageChange"
      ></ActivityList>
      <Lack
        v-if="list.length == 0"
        imgWidth="115px"
        imgHeight="72px"
        :imgSrc="require('@/assets/image/img/12.png')"
        text="暂无活动"
      ></Lack>
    </div>
    <Loading v-if="isShow"></Loading>
  </div>
</template>

<script>
import { activeList } from '@/service/api/active'
import Lack from '@/components/lack/lack.vue'
import Menu from '@/components/menu/menu.vue'
import ActivityList from '@/components/list/activity'
export default {
  components: { Menu, Lack, ActivityList },
  data() {
    return {
      params: {},
      list: [],
      total: 0,
      isShow: true
    }
  },

  mounted() {},

  methods: {
    // 活动列表
    async activeList() {
      const res = await activeList(this.params)
      this.list = res.msg.data
      this.total = res.msg.total
      this.isShow = false
      // console.log(res)
    },
    menuChange(params) {
      this.params = params
      // 活动列表
      this.activeList()
    },
    search(key) {
      this.params.page = 1
      this.params.key_words = key
      // 赛事列表
      this.activeList()
    },
    pageChange(page) {
      this.params.page = page
      this.activeList()
    }
  }
}
</script>

<style lang="less" scoped>
input::-webkit-input-placeholder {
  font-size: 14px !important;
  color: #ccc;
}
.header {
  width: 100%;
  height: 300px;
  img {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
}
.list {
  margin-top: 20px;
  margin-bottom: 40px;
}
</style>
