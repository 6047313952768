<template>
  <div class="lack flex_direction_column">
    <img :style="{ height: imgHeight, width: imgWidth }" :src="imgSrc" />
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    imgSrc: {
      type: String,
      default() {
        return ''
      }
    },
    imgWidth: {
      type: String,
      default() {
        return ''
      }
    },
    imgHeight: {
      type: String,
      default() {
        return ''
      }
    },
    text: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="less" scoped>
.lack {
  margin: 113px 0;
  align-items: center;
  justify-content: center;
  img {
    width: 115px;
    height: 72px;
  }
  span {
    height: 20px;
    margin-top: 18px;
    color: #aaaaaa;
    line-height: 20px;
    text-align: center;
    font-size: @font_size_14;
  }
}
</style>
