import { service } from '../index'

// 赛事分类数据
export function matchClassify() {
  return service.post({
    url: '/index/matchClassify'
  })
}

// 获取赛事城市数据
export function matchCity(data) {
  return service.post({
    url: '/index/matchCity',
    data
  })
}

// 赛事列表
export function matchList(data) {
  return service.post({
    url: '/index/matchList',
    data
  })
}

// 赛事详情
export function matchDetail(data) {
  return service.post({
    url: '/index/matchDetail',
    data
  })
}

// 赛事报名查询
export function enrollQuery(data) {
  return service.post({
    url: '/index/enrollQuery',
    data
  })
}

// 赛事查询结果
export function enrollQueryDo(data) {
  return service.post({
    url: '/index/enrollQueryDo',
    data
  })
}

// 赛事提交表单页面
export function matchOrder(data) {
  return service.post({
    url: '/order/matchOrder',
    data
  })
}

// 赛事报名提交
export function addMatchOrder(data) {
  return service.post({
    url: '/order/addMatchOrder',
    data
  })
}
